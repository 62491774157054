<template lang="pug" functional>
  .task-log
    .task-log__info {{ props.log.date_created | formattedDatetime }} by {{ props.log.author }}
    .task-log__comment {{ props.log.comment }}
</template>

<script>
export default {
  props: {
    log: Object
  }
}
</script>

<style lang="scss" scoped>
.task-log {
  margin-top: 8px;
  padding: 8px 8px 16px 8px;
  box-shadow: 0 1px 0 0 #e7e7e7;

  &__info, &__comment {
    font-size: 11px;
    font-family: $font;
    color: $placeholder-color;
  }

  &__comment {
    margin-top: 8px;
    font-weight: 500;
  }

  &__info {
    font-weight: bold;
  }
}
</style>
